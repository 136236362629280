import create from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

const inititalState = {
  pool: {
    poolwidth: 30, 
    pooldepth: 15, 
    poollength: 85,
    wallwidth: 0.2,
    ribwidth: 0.2,
    stair_amount: 4,
    stair_chosen: "completo_lounge",
    cover_chosen: "nis",
    pool_plage: false,
    rotate180: false,
    stairsleft: 'both',
    vijver: false,
    lights_amount: 5,
    lamps_diepte: 500,
    lights_hidden: true,
    light_position_left: true,
    light_switch: false,
    lamp_x_offset: 0,
    marker_type: "jetstream",
    drawingplan: false,
    extra_bar: false,
    vijverdikte: false,
    screenshot: false
  },
  arrows: {
    lengtharrow: false, 
    lengtharrow_position: [],
    widtharrow: false, 
    widtharrow_position: [],
    deptharrow: false,
    deptharrow_position: []
  },
  legende: {
    open: false
  },
  camera: {
    position: [0, 18, 80],
  },
  deleteMarker: {
    wall: "", 
    index: 0,
    markers: []
  },
  marker: {
    hidden: true,
    position: [],
    color: "",
    type: "",
    size: 0,
    max: 0,
    min: 0,
    shape: "",
    position1: [],
    wall: "",
    step: 3
  },
  tracker: {
    buislengthY: 0,
    nulPuntY: 0,
    buislengthZ: 0,
    nulPuntZ: 0,
    buislengthX: 0,
    nulPuntX: 0
  },
  frontwall: {
    markers : []
  },
  backwall: {
    markers : []
  },
  leftwall: {
    markers : []
  },
  rightwall: {
    markers : []
  },
  vijverwall: {
    markers : []
  }
};

export const useStore = create((set) => ({
  ...{ ...inititalState },
  updateObject: (object, field, value) =>
    set(
      (state) =>
        (state = {
          ...state,
          [object]: { ...state[object], [field]: value },
        })
    )
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Store", useStore);
}
