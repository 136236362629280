import { useStore } from '../../store/store';
import Shadermateriaal from '../shadermateriaal';
import RightwallLogic from './right';

const Rightwall = () => {
  const {
    poolwidth, 
    poollength, 
    pooldepth, 
    wallwidth, 
    ribwidth, 
    drawingplan,
    extra_bar
  } = useStore((state) => state.pool)

  const ribs_amount_length = Math.ceil((poollength - 2) / 5);

  return (
    <group name='rightwall' userData={{ type: 'panel' }} position={[-poolwidth / 2, -(pooldepth / 2) - 0.05, 0]}>
      <mesh name="rightwall_shelf" position={[-(wallwidth / 2 + (ribwidth * 8) / 2), pooldepth / 2 - 2, 0]}>
        <boxBufferGeometry args={[ribwidth * 8, 0.5,  drawingplan ? poollength + (ribwidth * 1) : poollength + (ribwidth * 4)]} />
        {drawingplan ?
            <Shadermateriaal/>
          :
            <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
        }
        
      </mesh>

      <group name="rightwall_pipe" position={[-(wallwidth / 2 + (ribwidth * 3) / 2), pooldepth / 2 - 5, 0]}>
        <mesh position={[0, 0, -poollength / 2 - ribwidth * 2]}>
          <sphereBufferGeometry args={[0.25, 20, 20]}/>
          {drawingplan ?
              <Shadermateriaal/>
            :
            <meshStandardMaterial color={0xffffff} emissive={0x7a7a7a7a} roughness={0.5} metalness={1}/>
          }
        </mesh>
        <mesh rotation={[drawingplan ? 0 : Math.PI / 2, 0, 0]}>
          {drawingplan ?
              <boxBufferGeometry args={[0.50, 0.50, poollength + ribwidth * 6 - 0.35, 32]}/>
            :
              <cylinderBufferGeometry args={[0.25, 0.25, poollength + ribwidth * 6 - 0.35, 32]}/>
          }
          {drawingplan ?
              <Shadermateriaal/>
            :
              <meshStandardMaterial color={0xffffff} emissive={0x7a7a7a7a} roughness={0.5} metalness={1}/>
          }
        </mesh>
      </group>

      {extra_bar &&
        <group name="rightwall_pipe_extra" position={[-(wallwidth / 2 + (ribwidth * 3) / 2), pooldepth / 2 - 7.5, 0]}>
          <mesh position={[0, 0, -poollength / 2 - ribwidth * 2]}>
            <sphereBufferGeometry args={[0.25, 20, 20]}/>
            {drawingplan ?
                <Shadermateriaal/>
              :
              <meshStandardMaterial color={0xffffff} emissive={0x7a7a7a7a} roughness={0.5} metalness={1}/>
            }
          </mesh>
          <mesh rotation={[drawingplan ? 0 : Math.PI / 2, 0, 0]}>
            {drawingplan ?
                <boxBufferGeometry args={[0.50, 0.50, poollength + ribwidth * 6 - 0.35, 32]}/>
              :
                <cylinderBufferGeometry args={[0.25, 0.25, poollength + ribwidth * 6 - 0.35, 32]}/>
            }
            {drawingplan ?
                <Shadermateriaal/>
              :
              <meshStandardMaterial color={0xffffff} emissive={0x7a7a7a7a} roughness={0.5} metalness={1}/>
            }
          </mesh>
        </group>
      }


      <group name="rightwall_ribs" position={[-(wallwidth / 2 + ribwidth * 1.5), -1, -poollength / 2 + 1]}>
        {
          [...Array(ribs_amount_length + 1)].map((e , i) => (
            <mesh key={i} name={`rightwall rib ${i}`} position={[0, 0, i * ((poollength - 2) / ribs_amount_length)]}>
              <boxBufferGeometry args={[ribwidth * 4, pooldepth - 2, ribwidth]}/>
               {drawingplan ?
                    <Shadermateriaal/>
                  :
                    <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
                }
            </mesh>
          ))
        }
      </group>

      <RightwallLogic/>
    </group>
  )
}

export default Rightwall