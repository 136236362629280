import { useStore } from "../../store/store"
import Dae from "../exporters/dae"
import Gltf from "../exporters/gltf"
import Json from "../exporters/json"
import marker_data from '../../data/markers.json' 

const Controls = ({meshRef}) => {
  const updateObject = useStore((state) => state.updateObject)
  const type = useStore((state) => state.marker.type)
  const {
    poolwidth,
    poollength,
    pooldepth,
    pool_plage,
    stair_chosen,
    cover_chosen,
    rotate180,
    stairsleft,
    vijver,
    light_position_left,
    lights_amount,
    light_switch,
    lamps_diepte,
    lights_hidden,
    drawingplan,
    extra_bar,
    vijverdikte
  } = useStore((state) => state.pool)
  const { lengtharrow, widtharrow, deptharrow} = useStore((state) => state.arrows)

  return (
    <div className='controls'>
      {/* POOL */}
      <h3>poolbuild</h3>
      <p>width: {poolwidth}</p>
      <input 
        type="number" 
        min="0" 
        value={poolwidth} 
        onChange={(e) => {
          if (isNaN(e.target.valueAsNumber)) {
            updateObject("pool", "poolwidth",  30)
          } else {
            updateObject("pool", "poolwidth",  e.target.valueAsNumber)
          }
        }}
      />

      <p>length: {poollength}</p>
      <input 
        type="number" 
        min="0" 
        value={poollength} 
        onChange={(e) => {
          if (isNaN(e.target.valueAsNumber)) {
            updateObject("pool", "poollength", 85)
          } else {
            updateObject("pool", "poollength", e.target.valueAsNumber)
          }
        }}
      />

      <p>depth: {pooldepth}</p>
      <input 
        type="number" 
        min="0" 
        value={pooldepth} 
        onChange={(e) => {
          if (isNaN(e.target.valueAsNumber)) {
            updateObject("pool", "pooldepth", 15)
          } else {
            updateObject("pool", "pooldepth", e.target.valueAsNumber)
          }
        }}
      />
      <input 
          type="checkbox" 
          name="extra bar" 
          id="extra bar" 
          onChange={() => {
            updateObject("pool", "extra_bar", !extra_bar)
          }}
          checked={extra_bar}
        />extra bar


      {/* ARROWS */}
      <h3>arrows</h3>
      <input 
        type="checkbox" 
        name="lengtharrow" 
        id="lengtharrow" 
        onChange={() => {
          updateObject("arrows", "lengtharrow", !lengtharrow)
        }}
        checked={lengtharrow}
      />length arrow

      <input 
        type="checkbox" 
        name="widtharrow" 
        id="widtharrow" 
        onChange={() => {
          updateObject("arrows", "widtharrow", !widtharrow)
        }}
        checked={widtharrow}
      />width arrow

      <input 
        type="checkbox" 
        name="deptharrow" 
        id="deptharrow" 
        onChange={() => {
          updateObject("arrows", "deptharrow", !deptharrow)
        }}
        checked={deptharrow}
      />depth arrow


      {/* Camera */}
      <h3>camera</h3>
      <button
        onClick={() => {
          updateObject("camera", "position", [0, 18, 80])
          updateObject("arrows", "lengtharrow_position", [-poolwidth/2, 2, 0])
          updateObject("arrows", "widtharrow_position", [0, 2, poollength/2+2.5])
          updateObject("arrows", "deptharrow_position", [-poolwidth/2, -pooldepth/2, poollength/2+2.5])
        }}
      >
        start
      </button>

      <button
        onClick={() => {
          updateObject("camera", "position", [0, 150, 0])
          updateObject("arrows", "lengtharrow_position", [-poolwidth/2, 2, 0])
          updateObject("arrows", "widtharrow_position", [0, 2, poollength/2+2.5])
          updateObject("arrows", "deptharrow_position", [-poolwidth/2, -pooldepth/2, poollength/2+2.5])
        }}
      >
        up
      </button>

      <button
        onClick={() => {
          updateObject("camera", "position", [0, -160, 0])
          updateObject("arrows", "lengtharrow_position", [-poolwidth/2,  -pooldepth -2, 0])
          updateObject("arrows", "widtharrow_position", [0, -pooldepth - 2, poollength/2+2.5])
          updateObject("arrows", "deptharrow_position", [-poolwidth/2, -pooldepth/2, poollength/2+2.5])
        }}
      >
        down
      </button>

       <button
        onClick={() => {
          updateObject("camera", "position", [-150, 0, 0])
          updateObject("arrows", "lengtharrow_position", [poolwidth/2, 2, 0])
          updateObject("arrows", "widtharrow_position", [0, 2, poollength/2+2.5])
          updateObject("arrows", "deptharrow_position", [poolwidth/2, -pooldepth/2, poollength/2+2.5])
        }}
      >
        left
      </button>

        <button
        onClick={() => {
          updateObject("camera", "position", [150, 0, 0])
          updateObject("arrows", "lengtharrow_position", [-poolwidth/2, 2, 0])
          updateObject("arrows", "widtharrow_position", [0, 2, poollength/2+2.5])
          updateObject("arrows", "deptharrow_position", [-poolwidth/2, -pooldepth/2, poollength/2+2.5])
        }}
      >
        right
      </button>

       <button
        onClick={() => {
          updateObject("camera", "position", [0, 0, 150])
          updateObject("arrows", "lengtharrow_position", [-poolwidth/2, 2, 0])
          updateObject("arrows", "widtharrow_position", [0, 2, -poollength/2 - 2.5])
          updateObject("arrows", "deptharrow_position", [-poolwidth/2, -pooldepth/2, -poollength/2 -2.5])
        }}
      >
        front
      </button>

        <button
        onClick={() => {
          updateObject("camera", "position", [0, 0, -150])
          updateObject("arrows", "lengtharrow_position", [-poolwidth/2, 2, 0])
          updateObject("arrows", "widtharrow_position", [0, 2, poollength/2+2.5])
          updateObject("arrows", "deptharrow_position", [-poolwidth/2, -pooldepth/2, poollength/2+2.5])
        }}
      >
        back
      </button>


      {/* TRAPPEN */}
      <h3>trappen</h3>
      <input 
        type="radio" 
        name="trap" 
        id="stucta_Lounge" 
        onChange={() => updateObject("pool", "stair_chosen", "stucta_Lounge")}
        disabled={pool_plage}
        checked={stair_chosen === "stucta_Lounge"}
      />stucta_Lounge

      <input 
        type="radio" 
        name="trap" 
        id="stucta" 
        onChange={() => updateObject("pool", "stair_chosen", "stucta")}
        checked={stair_chosen === "stucta"}
      />stucta

      <input 
        type="radio" 
        name="trap" 
        id="completo_lounge" 
        onChange={() => updateObject("pool", "stair_chosen", "completo_lounge")}
        disabled={pool_plage}
        checked={stair_chosen === "completo_lounge"}
      />completo_lounge

      <input 
        type="radio" 
        name="trap" 
        id="completo" 
        onChange={() => updateObject("pool", "stair_chosen", "completo")}
        checked={stair_chosen === "completo"}
      />completo

      <input 
        type="radio" 
        name="trap" 
        id="enduro" 
        onChange={() => updateObject("pool", "stair_chosen", "enduro")}
        disabled={pool_plage}
        checked={stair_chosen === "enduro"}
      />enduro

      <div>
      <input 
        type="checkbox" 
        name="left side" 
        id="left side" 
        onChange={() => updateObject("pool", "stairsleft", 'right')}
        checked={stairsleft === 'right'}
      />right side
      <input 
        type="checkbox" 
        name="left side" 
        id="left side" 
        onChange={() => updateObject("pool", "stairsleft", 'left')}
        checked={stairsleft === 'left'}
      />left side
      <input 
        type="checkbox" 
        name="left side" 
        id="left side" 
        onChange={() => updateObject("pool", "stairsleft", 'both')}
        checked={stairsleft === 'both'}
      />both sides
      </div>


      {/* COVER */}
      <h3>cover</h3>
      <div>
        <input 
          type="radio" 
          name="cover" 
          id="nis" 
          onChange={() => {
            updateObject("pool", "cover_chosen", "nis")
            updateObject("pool", "pool_plage", false)
          }}
          checked={cover_chosen === "nis"}
          disabled={vijver}
        />nis

        <input 
          type="checkbox" 
          name="plage nis" 
          id="plage nis" 
          onChange={() => {
            updateObject("pool", "pool_plage", !pool_plage)
            if (pool_plage && (stair_chosen !== "stucta" || stair_chosen !== "completo")) {
              updateObject("pool", "stair_chosen", "stucta")
            }
          }}
          checked={pool_plage}
        />plage voor nis
      </div>

      <input 
        type="radio" 
        name="cover" 
        id="plage" 
        onChange={() => {
          updateObject("pool", "cover_chosen", "plage")
          updateObject("pool", "pool_plage", true)
          if (pool_plage && (stair_chosen !== "stucta" || stair_chosen !== "completo")) {
            updateObject("pool", "stair_chosen", "stucta")
          }
        }}
        checked={cover_chosen === "plage"}
      />plage


      {/* LIGHTS */}
      <h3>lights</h3>
      <input 
        type="checkbox" 
        name="hidden" 
        id="hidden" 
        onChange={() => updateObject("pool", "lights_hidden", !lights_hidden)}
        checked={lights_hidden}
      />hide lights
      <input 
        type="radio" 
        name="light" 
        id="left" 
        onChange={() => updateObject("pool", "light_position_left", true)}
        checked={light_position_left}
      />lights left

      <input 
        type="radio" 
        name="light" 
        id="right" 
        onChange={() => updateObject("pool", "light_position_left", false)}
        checked={!light_position_left}
      />lights right

      <input 
        type="checkbox" 
        name="switch" 
        id="switch" 
        onChange={() => updateObject("pool", "light_switch", !light_switch)}
        checked={light_switch}
      />switch lights

      <p>lights amount: {lights_amount}</p>
      <input 
        type="number" 
        min="0" 
        value={lights_amount} 
        max="6" 
        onChange={(e) => {
          if (isNaN(e.target.valueAsNumber)) {
            updateObject("pool", "lights_amount", 0)
          } else {
            updateObject("pool", "lights_amount", e.target.valueAsNumber)
          }
        }}
      />

      <p>lamps_diepte: {lamps_diepte}</p>
      <input 
        type="number" 
        min="0" 
        value={lamps_diepte} 
        onChange={(e) => {
          if (isNaN(e.target.valueAsNumber)) {
            updateObject("pool", "lamps_diepte", 500)
          } else {
            updateObject("pool", "lamps_diepte", e.target.valueAsNumber)
          }
        }}
      />



      {/* EXTRAS */}
      <h3>Extras</h3>
      <input 
        type="checkbox" 
        name="rotate180" 
        id="rotate180" 
        onChange={() => updateObject("pool", "rotate180", !rotate180)}
        checked={rotate180}
      />rotate 180deg
      <input 
        type="checkbox" 
        name="vijver" 
        id="vijver" 
        onChange={() => {
          updateObject("pool", "vijver", !vijver)
          if (!vijver) {
            updateObject("pool", "cover_chosen", "plage")
            updateObject("pool", "pool_plage", true)
          }
        }}
        checked={vijver}
      />vijver
       <input 
        type="checkbox" 
        name="drawingplan" 
        id="vijverdikte" 
        onChange={() => updateObject("pool", "vijverdikte", !vijverdikte)}
        checked={vijverdikte}
      />vijverdikte

      <input 
        type="checkbox" 
        name="drawingplan" 
        id="drawingplan" 
        onChange={() => updateObject("pool", "drawingplan", !drawingplan)}
        checked={drawingplan}
      />Plan shader


      {/* MARKERS */}
      <h3>Markers</h3>
      {marker_data.markers.map((marker) => (
        <button
          key={marker.type}
          style={{border: type === marker.type ? "solid 1px red" : "none", margin: 2}}
          onClick={() => {
            updateObject("marker", "color", marker.color)
            updateObject("marker", "type", marker.type)
            updateObject("marker", "max", marker.max)
            updateObject("marker", "min", marker.min)
            updateObject("marker", "size", marker.size)
            updateObject("marker", "shape", marker.shape)
          }}
        >
          {marker.type}
        </button>
      ))}


      {/* DOWNLOADS */}
      <h3>Downloads</h3>
      <Dae meshRef={meshRef}/>
      <Gltf meshRef={meshRef}/>
      <Json/>
    </div>
  )
}

export default Controls