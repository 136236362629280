import { useStore } from '../../store/store';
import Shadermateriaal from '../shadermateriaal';
import BackwallLogic from './back';


const Backwall = () => {
  const {
    poolwidth, 
    poollength, 
    pooldepth, 
    wallwidth, 
    ribwidth, 
    drawingplan,
    extra_bar
  } = useStore((state) => state.pool)

  const ribs_amount_width = Math.ceil((poolwidth - 2) / 5);

  return (
    <group name="backwall" userData={{ type: 'panel' }} position={[0, -(pooldepth / 2) - 0.05, -poollength / 2]}>
      <mesh name="backwall_shelf" position={[0, pooldepth / 2 - 2, -(wallwidth / 2 + (ribwidth * 8) / 2)]}>
        <boxBufferGeometry args={[poolwidth + (ribwidth * 16) + 0.2, 0.5, ribwidth * 8]} />
        {drawingplan ?
            <Shadermateriaal/>
          :
            <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
        }
      </mesh>

      <group name="backwall_pipe" position={[0, pooldepth / 2 - 5, -(wallwidth / 2 + (ribwidth * 3) / 2)]}>
        <mesh position={[+poolwidth / 2 + ribwidth * 2, 0, 0]}>
          <sphereBufferGeometry args={[0.25, 20, 20]}/>
          {drawingplan ?
              <Shadermateriaal/>
            :
            <meshStandardMaterial color={0xffffff} emissive={0x7a7a7a7a} roughness={0.5} metalness={1}/>
          }
        </mesh>
        <mesh rotation={drawingplan ? [0, Math.PI / 2, 0] : [0, 0, Math.PI / 2]}>
          {drawingplan ?
              <boxBufferGeometry args={[0.50, 0.50, poolwidth + ribwidth * 6 - 0.35, 32]}/>
            :
              <cylinderBufferGeometry args={[0.25, 0.25, poolwidth + ribwidth * 6 - 0.35, 32]}/>
          }
          {drawingplan ?
              <Shadermateriaal/>
            :
            <meshStandardMaterial color={0xffffff} emissive={0x7a7a7a7a} roughness={0.5} metalness={1}/>
          }
        </mesh>
      </group>

      {extra_bar &&
        <group name="backwall_pipe_extra" position={[0, pooldepth / 2 - 7.5, -(wallwidth / 2 + (ribwidth * 3) / 2)]}>
          <mesh position={[+poolwidth / 2 + ribwidth * 2, 0, 0]}>
            <sphereBufferGeometry args={[0.25, 20, 20]}/>
            {drawingplan ?
                <Shadermateriaal/>
              :
              <meshStandardMaterial color={0xffffff} emissive={0x7a7a7a7a} roughness={0.5} metalness={1}/>
            }
          </mesh>
          <mesh rotation={drawingplan ? [0, Math.PI / 2, 0] : [0, 0, Math.PI / 2]}>
            {drawingplan ?
                <boxBufferGeometry args={[0.50, 0.50, poolwidth + ribwidth * 6 - 0.35, 32]}/>
              :
                <cylinderBufferGeometry args={[0.25, 0.25, poolwidth + ribwidth * 6 - 0.35, 32]}/>
            }
            {drawingplan ?
                <Shadermateriaal/>
              :
              <meshStandardMaterial color={0xffffff} emissive={0x7a7a7a7a} roughness={0.5} metalness={1}/>
            }
          </mesh>
        </group>
      }

      <group name="backwall_ribs" position={[-poolwidth / 2 + 1, -1, -(wallwidth / 2 + ribwidth)]}>
        {
          [...Array(ribs_amount_width + 1)].map((e , i) => (
            <mesh key={i} name={`backwall rib ${i}`} position={[i * ((poolwidth - 2) / ribs_amount_width), 0, -.1]}>
              <boxBufferGeometry args={[ribwidth, pooldepth - 2, ribwidth * 4]}/>
              {drawingplan ?
                  <Shadermateriaal/>
                :
                  <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
              }
            </mesh>
          ))
        }
      </group>

    <BackwallLogic/>
    </group>
  )
}

export default Backwall