import {useStore} from "../../store/store"
import TrackingArrowY from "./TrackingArrowY"
import TrackingArrowX from "./TrackingArrowX"
import TrackingArrowZ from "./TrackingArrowZ"

const Marker = () => {
  const {shape, position1, color, hidden, size, position, wall} = useStore((state) => state.marker)

  const isempty = position1.length === 0

  return (
    !hidden &&
      shape === "line" && !isempty ? (
        <group>
           <mesh position={[position1[0], -position1[1], -position1[2]]}>
            <boxBufferGeometry args={[size, size, size]}/>
            <meshStandardMaterial color={color}/>
          </mesh>

          <mesh position={[position1[0], -position1[1], (-position1[2] - -position[2])/2]}>
            <boxBufferGeometry args={[size/2, size/2, Math.sqrt( Math.pow((-position1[2] + -position[2]), 2) + Math.pow((-position1[1] + -position[1]), 0)) -size]}/>
            <meshStandardMaterial color={color}/>
          </mesh>

          <mesh position={position}>
            <boxBufferGeometry args={[size, size, size]}/>
            <meshStandardMaterial color={color}/>
          </mesh>
        </group>
      ): (
        <>
          <mesh position={position}>
            <boxBufferGeometry args={[size, size, size]}/>
            <meshStandardMaterial color={color}/>
          </mesh>

          {wall !== "" &&
            <TrackingArrowY position={position}/>
          }

          {wall !== "" &&
            <TrackingArrowZ position={position}/>
          }
        </>
      )
  )
}

export default Marker