import { useStore } from "../../store/store"
import Shadermateriaal from "../shadermateriaal"

const Enduro = () => {
  const {
    stair_amount,
    pooldepth,
    poolwidth,
    poollength,
    stairsleft,
    drawingplan
  } = useStore((state) => state.pool)

  return (
    <>
      {(stairsleft === "right" || stairsleft === 'both') &&        
        <group name="stairs" position={drawingplan ? [-poolwidth / 2.02 + (5 / 2), 0, (poollength / 2) - 0.1] : [-poolwidth / 2 + (5 / 2), -.1, (poollength / 2) - 0]}>
          {
            [...Array(stair_amount  * 2)].map((e , i) => (
              <mesh key={i} name={`stairstep ${i}`} position={[0, -(pooldepth / 2) - 1 - (i * .8), - (i * 3.7) - 1.85]}>
                <boxBufferGeometry args={[5, pooldepth - 2 - (1.6 * i), 3.7]}/>
                {drawingplan ?
                    <Shadermateriaal/>
                  :
                    <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
                }
              </mesh>
            ))
          }
        </group>
      }
      {(stairsleft === "left" || stairsleft === 'both') &&          
        <group name="stairs" position={drawingplan ? [poolwidth / 2.42, 0, (poollength / 2) - 0.1]: [poolwidth / 2.4, 0, (poollength / 2) - 0]}>
          {
            [...Array(stair_amount  * 2)].map((e , i) => (
              <mesh key={i} name={`stairstep ${i}`} position={[0, -(pooldepth / 2) - 1 - 0.05 - (i * 0.8), - (i * 3.7) - 1.85]}>
                <boxBufferGeometry args={[5, pooldepth - 2 - (1.6 * i), 3.7]}/>
                {drawingplan ?
                    <Shadermateriaal/>
                  :
                    <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
                }
              </mesh>
            ))
          }
        </group>
      }
    </>
  )
}

export default Enduro