import { useStore } from '../../store/store';
import Shadermateriaal from '../shadermateriaal';

const Floor = () => {
  const {
    poolwidth, 
    poollength, 
    pooldepth, 
    wallwidth, 
    drawingplan
  } = useStore((state) => state.pool)

  return (
    <group name="floorgroup" userData={{ type: 'panel' }}>
      {/* -0.2 --> -0.05 */}
      <mesh name="floor" position={[0,drawingplan ? -pooldepth -.2 : -pooldepth - .1, 0]}>
        <boxBufferGeometry args={[poolwidth + 2, wallwidth, poollength + 2]} />
        {drawingplan ?
            <Shadermateriaal/>
          :
            <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.1}/>
        }
      </mesh>
    </group>
  )
}

export default Floor