import React, { useMemo } from 'react'

const Sprite = ({ children, position, scale, color = 'black', fontSize = 45 }) => {
  const canvas = useMemo(() => {
    const fontface = 'Arial'
    const fontsize = fontSize
    const borderThickness = 4

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    context.textBaseline = 'middle'
    context.font = `bold ${fontSize}px ${fontface}`

    const metrics = context.measureText(children)
    const textWidth = metrics.width

    context.lineWidth = borderThickness

    context.fillStyle = color
    context.fillText(children, textWidth - textWidth * 0.8, fontsize)
    return canvas
  }, [children])

  return (
    <sprite scale={scale} position={position}>
      <spriteMaterial sizeAttenuation={true} attach="material" transparent alphaTest={0.5} depthTest={false}>
        <canvasTexture attach="map" image={canvas}/>
      </spriteMaterial>
    </sprite>
  )
}

export default Sprite