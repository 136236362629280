import LengthArrow from "./lengthArrow"
import WidthArrow from "./widthArrow"
import DepthArrow from "./depthArrow"
import { useStore } from "../../store/store"

const Arrows = () => {
  const {lengtharrow, widtharrow, deptharrow} = useStore((state) => state.arrows)

  return (
    <>
      {lengtharrow &&
        <LengthArrow/>
      }
      {widtharrow &&
        <WidthArrow/>
      }
      {deptharrow &&
        <DepthArrow/>
      }
    </>
  )
}

export default Arrows