import { useStore } from "../../store/store"
import Shadermateriaal from "../shadermateriaal"

const Stucta = () => {
  const {
    stair_amount,
    pooldepth,
    poolwidth,
    poollength,
    pool_plage,
    stairsleft,
    drawingplan
  } = useStore((state) => state.pool)

  return (
    <>
      {(stairsleft === "right" || stairsleft === 'both') &&
        <group name="stairs" 
          position={
            pool_plage ? 
              drawingplan ? [-poolwidth / 2 + (5 / 2), -1.048, (poollength / 2) - 8.05] : [-poolwidth / 2 + (5 / 2), -1, (poollength / 2) - 8]
                : 
              drawingplan ? [-poolwidth / 2 + (5 / 2), -1.1, (poollength / 2) - 0.25] : [-poolwidth / 2 + (5 / 2), -1, (poollength / 2)]
          }>
          {
            [...Array(stair_amount +1)].map((e , i) => (
              <mesh key={i} name={`stairstep ${i}`} position={drawingplan ? [0.1, -(pooldepth / 2) - (i * 1.3), - (i * 3.7) - 1.75] : [0, -(pooldepth / 2) - (i * 1.3), - (i * 3.7) - 1.7]}>
                <boxBufferGeometry args={[5, pooldepth - 2 - (2.6 * i), 3.7]}/>
                {drawingplan ?
                    <Shadermateriaal/>
                  :
                    <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
                }
              </mesh>
            ))
          }
        </group>
      }
      {(stairsleft === "left" || stairsleft === 'both') && 
        <group name="stairs" 
          position={
            pool_plage ? 
              drawingplan ? [poolwidth / 2.45, -1.048, (poollength / 2) - 8.05] : [poolwidth / 2.45, -1, (poollength / 2) - 8] 
                : 
              drawingplan ? [poolwidth / 2.45, -1.1, (poollength / 2) - 0.25] : [poolwidth / 2.4, -1, (poollength / 2)]
          }>
          {
            [...Array(stair_amount +1)].map((e , i) => (
              <mesh key={i} name={`stairstep ${i}`} position={drawingplan ? [0.1, -(pooldepth / 2) - (i * 1.3), - (i * 3.7) - 1.75] : [0, -(pooldepth / 2) - (i * 1.3), - (i * 3.7) - 1.7]}>
                <boxBufferGeometry args={[5, pooldepth - 2 - (2.6 * i), 3.7]}/>
                {drawingplan ?
                    <Shadermateriaal/>
                  :
                    <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
                }
              </mesh>
            ))
          }
        </group>
      }
    </>
  )
}

export default Stucta