export const AddZMarker = (wall, e, pooldepth, updateObject, wallMarkers, marker) => {
  e.stopPropagation()

  let stepPosition = 0

  //calc e.point.x || e.point.z step 
  if (e.point.z % marker.step < marker.step/2) {
    stepPosition = e.point.z - e.point.z % marker.step
  }
  if (e.point.z % marker.step >= marker.step/2) {
    stepPosition = (e.point.z - e.point.z % marker.step) + marker.step
  }

  if (marker.type !== "") {
    const markers = [...wallMarkers]
    let mark
    
    if (e.point.y < -pooldepth + marker.max && e.point.y >  -pooldepth + marker.min) {
      mark = {x: e.point.x, y: e.point.y, z: stepPosition, color: marker.color, type: marker.type, size: marker.size, shape: marker.shape}
    } 
    if (e.point.y > -pooldepth + marker.max) {
      mark = {x: e.point.x, y: -pooldepth + marker.max, z: stepPosition, color: marker.color, type: marker.type, size: marker.size, shape: marker.shape}
    }
    if (e.point.y < -pooldepth + marker.min) {
      mark = {x: e.point.x, y: -pooldepth + marker.min, z: stepPosition, color: marker.color, type: marker.type, size: marker.size, shape: marker.shape}
    }
    markers.push(mark)
    updateObject(wall, "markers", markers)
  }
}

export const addZMarkerLine = (wall, e, wallMarkers, pooldepth, updateObject, marker, isempty) => {
  e.stopPropagation()
  const markers = [...wallMarkers]

  let stepPosition = 0

  //calc e.point.x || e.point.z step 
  if (e.point.z % marker.step < marker.step/2) {
    stepPosition = e.point.z - e.point.z % marker.step
  }
  if (e.point.z % marker.step >= marker.step/2) {
    stepPosition = (e.point.z - e.point.z % marker.step) + marker.step
  }

  if (isempty) {
    if (e.point.y < -pooldepth + marker.max && e.point.y >  -pooldepth + marker.min) {
      updateObject("marker", "position1", [-e.point.x, -e.point.y, stepPosition])
    } 
    if (e.point.y > -pooldepth + marker.max) {
      updateObject("marker", "position1", [-e.point.x, pooldepth - marker.max, stepPosition])
    }
    if (e.point.y < -pooldepth + marker.min) {
      updateObject("marker", "position1", [-e.point.x, pooldepth - marker.min, stepPosition])
    }
    
  } else {
      const mark = {
        x1: -marker.position1[0], y1: marker.position1[1], z1: marker.position1[2],
        x2: e.point.x, y2: marker.position1[1], z2: stepPosition,
        color: marker.color, type: marker.type, size: marker.size, shape: marker.shape
      }
      markers.push(mark);
      updateObject(wall, "markers", markers)
      updateObject("marker", "position1", [])
  }
}

export const AddXMarker = (wall, e, pooldepth, updateObject, wallMarkers, marker) => {
  e.stopPropagation()

  let stepPosition = 0

  //calc e.point.x step 
  if (e.point.x % marker.step < marker.step/2) {
    stepPosition = e.point.x - e.point.x % marker.step
  }
  if (e.point.x % marker.step >= marker.step/2) {
    stepPosition = (e.point.x - e.point.x % marker.step) + marker.step
  }

  if (marker.type !== "") {
    const markers = [...wallMarkers]
    let mark
    
    if (e.point.y < -pooldepth + marker.max && e.point.y >  -pooldepth + marker.min) {
      mark = {x: stepPosition, y: e.point.y, z: e.point.z, color: marker.color, type: marker.type, size: marker.size, shape: marker.shape}
    } 
    if (e.point.y > -pooldepth + marker.max) {
      mark = {x: stepPosition, y: -pooldepth + marker.max, z: e.point.z, color: marker.color, type: marker.type, size: marker.size, shape: marker.shape}
    }
    if (e.point.y < -pooldepth + marker.min) {
      mark = {x: stepPosition, y: -pooldepth + marker.min, z: e.point.z, color: marker.color, type: marker.type, size: marker.size, shape: marker.shape}
    }
    markers.push(mark)
    updateObject(wall, "markers", markers)
  }
}

export const addXMarkerLine = (wall, e, wallMarkers, pooldepth, updateObject, marker, isempty) => {
  e.stopPropagation()
  const markers = [...wallMarkers]

  let stepPosition = 0

  //calc e.point.x step 
  if (e.point.x % marker.step < marker.step/2) {
    stepPosition = e.point.x - e.point.x % marker.step
  }
  if (e.point.x % marker.step >= marker.step/2) {
    stepPosition = (e.point.x - e.point.x % marker.step) + marker.step
  }

  if (isempty) {
    if (e.point.y < -pooldepth + marker.max && e.point.y >  -pooldepth + marker.min) {
      updateObject("marker", "position1", [-stepPosition, -e.point.y, e.point.z])
    } 
    if (e.point.y > -pooldepth + marker.max) {
      updateObject("marker", "position1", [-stepPosition, pooldepth - marker.max, e.point.z])
    }
    if (e.point.y < -pooldepth + marker.min) {
      updateObject("marker", "position1", [-stepPosition, pooldepth - marker.min, e.point.z])
    }
    
  } else {
      const mark = {
        x1: -marker.position1[0], y1: marker.position1[1], z1: marker.position1[2],
        x2: stepPosition, y2: marker.position1[1], z2: e.point.z,
        color: marker.color, type: marker.type, size: marker.size, shape: marker.shape
      }
      markers.push(mark);
      updateObject(wall, "markers", markers)
      updateObject("marker", "position1", [])
  }
}