import { useEffect, useState } from "react"
import { useStore } from "../../store/store"
import { Html } from "@react-three/drei";

const TrackingArrowZ = ({position}) => {
  const length = useStore((state) => state.pool.poollength)
  const updateObject = useStore((state) => state.updateObject)
  const {buislengthZ, nulPuntZ} = useStore((state) => state.tracker)
  const x = position[0]
  const y = position[1]
  const z = position[2]

  useEffect(() => {
    updateObject("tracker", "buislengthZ", length/2  - Math.abs(z))
    updateObject("tracker", "nulPuntZ", buislengthZ / 2)
  }, [z])

  return (
    <group name="length arrow" 
      position={[x, y, Math.sign(z) === -1 ? z - nulPuntZ : z + nulPuntZ]}
    >
      <group rotation={[0, Math.PI / 2, Math.PI / 2]}>
        <mesh position={[0, 0, 0]} >
          <cylinderBufferGeometry attach="geometry" args={[.2, .2, buislengthZ, 32]} />
          <meshStandardMaterial color={"grey"}/>
        </mesh>
      </group>

      <Html position={[0, 0, -4.5]}>
        <p> {buislengthZ}</p>
      </Html>
    </group>
  )
}

export default TrackingArrowZ