import "./legende.css"
import { useStore } from "../../store/store"

const Legende = () => {
  const legende_open = useStore((state) => state.legende.open)
  const updateObject = useStore((state) => state.updateObject)

  const data = [
    {name: 'lampen', color:'#FBFF22'},
    {name: 'jetstream', color:'#2496FF'},
    {name: 'aanzuig jetstream', color:'#0011AC'},
    {name: 'inspuiters', color:'#6E00FB'},
    {name: 'onderaanzuig', color:'#DD9200'},
    {name: 'stofzuigeraansluiting', color:'#FF74C7'},
    {name: 'goot door voer 110', color:'#68FF65'},
    {name: 'wand door voer 110', color:'#027000'},
    {name: 'piezo', color:'#00E5D8'},
    {name: 'handrail', color:'#FBFF22'},
    {name: 'veiligheidsrand', color:'#FF4343'},
    {name: 'niveau regelaar', color:'#000000'},
    {name: 'hoekjes voor lamellen begeleiding', color:'#522C00'}
  ]

  return (
    <div className="legende_container">
      <div className="legende_button" onClick={() => updateObject("legende", "open", !legende_open)}>
        <p>legende</p>
        <p>{`>`}</p>
      </div>
      <div className={legende_open ? "legende_content" : "legende_content_closed"}>
        {data.map((item) => (
          <div key={item.name}>
            <p>{item.name}</p>
            <div className="legende_content_color" style={{backgroundColor: item.color}}></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Legende