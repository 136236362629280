export const MoveZMarker = (e, pooldepth, updateObject, marker, wall) => {
  const isempty = marker.position1.length === 0
  e.stopPropagation()
  
  updateObject("marker", "hidden", false)
  updateObject("marker", "wall", wall)

  let stepPosition = 0

  //calc e.point.x || e.point.z step 
  if (e.point.z % marker.step < marker.step/2) {
    stepPosition = e.point.z - e.point.z % marker.step
  }
  if (e.point.z % marker.step >= marker.step/2) {
    stepPosition = (e.point.z - e.point.z % marker.step) + marker.step
  }

  if (marker.shape !== "line") {
    if (e.point.y < -pooldepth + marker.max && e.point.y > -pooldepth + marker.min) {
      updateObject("marker", "position", [-e.point.x, e.point.y, -stepPosition])
    } 
    if (e.point.y > -pooldepth + marker.max) {
      updateObject("marker", "position", [-e.point.x, -pooldepth + marker.max, -stepPosition])
    }
    if (e.point.y < -pooldepth + marker.min) {
      updateObject("marker", "position", [-e.point.x, -pooldepth + marker.min, -stepPosition])
    }
  } else {
    if (!isempty) {
      updateObject("marker", "position", [-e.point.x, -marker.position1[1], -stepPosition])
    } else {
      if (e.point.y < -pooldepth + marker.max && e.point.y > -pooldepth + marker.min) {
        updateObject("marker", "position", [-e.point.x, e.point.y, -stepPosition])
      } 
      if (e.point.y > -pooldepth + marker.max) {
        updateObject("marker", "position", [-e.point.x, -pooldepth + marker.max, -stepPosition])
      }
      if (e.point.y < -pooldepth + marker.min) {
        updateObject("marker", "position", [-e.point.x, -pooldepth + marker.min, -stepPosition])
      }
    }
  }
}

export const MoveXMarker = (e, pooldepth, updateObject, marker, wall) => {
  const isempty = marker.position1.length === 0
  e.stopPropagation()
  
  updateObject("marker", "hidden", false)
  updateObject("marker", "wall", wall)

  let stepPosition = 0

  //calc e.point.x step 
  if (e.point.x % marker.step < marker.step/2) {
    stepPosition = e.point.x - e.point.x % marker.step
  }
  if (e.point.x % marker.step >= marker.step/2) {
    stepPosition = (e.point.x - e.point.x % marker.step) + marker.step
  }

  if (marker.shape !== "line") {
    if (e.point.y < -pooldepth + marker.max && e.point.y > -pooldepth + marker.min) {
      updateObject("marker", "position", [-stepPosition, e.point.y, -e.point.z])
    } 
    if (e.point.y > -pooldepth + marker.max) {
      updateObject("marker", "position", [-stepPosition, -pooldepth + marker.max, -e.point.z])
    }
    if (e.point.y < -pooldepth + marker.min) {
      updateObject("marker", "position", [-stepPosition, -pooldepth + marker.min, -e.point.z])
    }
  } else {
    if (!isempty) {
      updateObject("marker", "position", [-stepPosition, -marker.position1[1], -e.point.z])
    } else {
      if (e.point.y < -pooldepth + marker.max && e.point.y > -pooldepth + marker.min) {
        updateObject("marker", "position", [-stepPosition, e.point.y, -e.point.z])
      } 
      if (e.point.y > -pooldepth + marker.max) {
        updateObject("marker", "position", [-stepPosition, -pooldepth + marker.max, -e.point.z])
      }
      if (e.point.y < -pooldepth + marker.min) {
        updateObject("marker", "position", [-stepPosition, -pooldepth + marker.min, -e.point.z])
      }
    }
  }
}


export const LeaveMarker = (e, updateObject, wall) => {
  e.stopPropagation()
  console.log(wall, "ben er uit");
  updateObject("marker", "position", [])
  updateObject("marker", "wall", "")
  updateObject("marker", "hidden", true)
}