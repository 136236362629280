import { useStore } from "../../store/store"
import StuctaLounge from "./stuctaLounge"
import Stucta from "./stucta"
import CompletoLounge from "./completoLounge"
import Completo from "./completo"
import Enduro from "./enduro"

const Stairs = () => {
  const {stair_chosen} = useStore((state) => state.pool)

  return (
    <>
      {stair_chosen === "stucta_Lounge" &&  
        <StuctaLounge/>
      }
      {stair_chosen === "stucta" && 
        <Stucta/>
      }
      {stair_chosen === "completo_lounge" &&  
        <CompletoLounge/>
      }
      {stair_chosen === "completo" &&  
        <Completo/>
      }
      {stair_chosen === "enduro" &&
        <Enduro/>
      }
    </>
  )
}

export default Stairs