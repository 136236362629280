import { useStore } from "../../store/store"
import Shadermateriaal from "../shadermateriaal"

const Completo = () => {
  const {
    stair_amount,
    pooldepth,
    poolwidth,
    poollength,
    pool_plage,
    drawingplan
  } = useStore((state) => state.pool)

  return (
    <group name="stairs" 
      position={
        pool_plage ? 
          drawingplan ? [0, 0.5, (poollength / 2) - 7.9] : [0, 0, (poollength / 2) - 8]
            : 
          drawingplan ? [0, 0, (poollength / 2) - 0.05] : [0, 0, (poollength / 2)]
      }>
      {
        [...Array(stair_amount +1 )].map((e , i) => (
          <mesh key={i} name={`stairstep ${i}`} position={[0, -(pooldepth / 2) - 1 - 0.05 - (i * 1.3), - (i * 3.65) - 1.85]}>
            <boxBufferGeometry args={[drawingplan ? poolwidth -.2 : poolwidth, pooldepth - 3 - (2.35 * i), 3.65]}/>
            {drawingplan ?
                <Shadermateriaal/>
              :
                <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
            }
          </mesh>
        ))
      }
    </group>
  )
}

export default Completo