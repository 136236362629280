import { useStore } from "../../store/store"
import "./markerbuttons.css"

const MarkerButtons = () => {
  const updateObject = useStore((state) => state.updateObject)
  const type = useStore((state) => state.marker.type)
  const {wall, index, markers} = useStore((state) => state.deleteMarker)

  console.log(index);

  const UnselectMarker = () => {
    updateObject("marker", "color", '')
    updateObject("marker", "type", '')
    updateObject("marker", "max", 0)
    updateObject("marker", "min", 0)
    updateObject("marker", "size", 0)
    updateObject("marker", "shape", '')
    updateObject("marker", "wall", '')
  }

  const DeleteMarker = () => {
    var array = [...markers]; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      updateObject(wall, "markers", array)

      updateObject('deleteMarker', "wall", '')
      updateObject('deleteMarker', "index", 0)
      updateObject('deleteMarker', "markers", [])
    }
  }

  const UnFocus = () => {
    updateObject("deleteMarker", "index", 0)
    updateObject("deleteMarker", "wall", "")
    updateObject("deleteMarker", "markers", [])
  }

  return (
    <div className="buttons">
      <button 
        onClick={() => UnselectMarker()}
        style={{border: type === "" ? "" : "2px solid red"}}
      >Unselect marker</button>
      <button 
        onClick={() => DeleteMarker()}
      >Delete marker</button>
      <button 
        onClick={() => UnFocus()}
        style={{border: markers.length === 0 ? "" : "2px solid red"}}
      >Unfocus marker</button>
    </div>
  )
}

export default MarkerButtons