import { useStore } from "../../store/store"

const Json = () => {
  const fullStore = useStore((state) => state) 

  const handleDownload = () => {
    // create file in browser
    const fileName = "data_pool";
    const json = JSON.stringify(fullStore, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
  
    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
  
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  return (
    <button onClick={() => handleDownload()}>Json file</button>
  )
}

export default  Json