import { useStore } from "../../store/store"
import { DoubleSide } from "three"
import { useEffect, useRef } from "react"

const Poollights = () => {
  const lightMarkersRef = useRef()
  const updateObject = useStore((state) => state.updateObject)
  const {
    lights_amount,
    light_position_left,
    poollength,
    poolwidth,
    lamps_diepte,
    light_switch,
    lamp_x_offset
  } = useStore((state) => state.pool)

  const lights_length = (poollength - 8) 
  const increment = (lights_length / (lights_amount + 1));
  const lamp_y_offset = lamps_diepte / 100

  useEffect(() => {
    if (lightMarkersRef.current !== undefined && light_switch) {
      updateObject("pool", "lamp_x_offset",  lightMarkersRef.current.position.x)
    } else {
      updateObject("pool", "lamp_x_offset", 0)
    }
  }, [light_switch, light_position_left])

  return (
    <group name="light_marker_group" ref={lightMarkersRef} position={[light_position_left ? poolwidth / 2 + 0.1 : -poolwidth / 2 - 0.1, - lamp_y_offset, -poollength / 2 +4]}>
      {
        [...Array(lights_amount)].map((e , i) => (
          <group  
            name={`lampmarker_${i}`} 
            key={`lampmarker_${i}`} 
            rotation={[90 * (Math.PI/180), 0, 90 * (Math.PI/180)]} 
            position={[lamp_x_offset !== 0 && i%2 ? -lamp_x_offset * 2 : 0, 0, increment * i + increment]}
            side={DoubleSide}
          >
            <mesh >
              <cylinderBufferGeometry args={[1.25, 1.25, 1.25, 32]}/>
              <meshStandardMaterial color={"yellow"} roughness={1} metalness={0.2}  side={DoubleSide}/>
            </mesh>
          </group>
        ))
      }
    </group>
  )
}

export default Poollights