import { useStore } from "../../store/store"
import Sprite from "../sprite"

const LengthArrow = () => {
  const length = useStore((state) => state.pool.poollength)
  const width = useStore((state) => state.pool.poolwidth)
  const position = useStore((state) => state.arrows.lengtharrow_position)

  return (
    <group name="length arrow" rotation={[0, Math.PI / 2, Math.PI / 2]} position={position.length === 0 ? [-width/2, 2, 0] : position}>
      <mesh position={[0, 0, 0]} >
        <cylinderBufferGeometry attach="geometry" args={[.1, .1, length, 32]} />
        <meshBasicMaterial color={"black"}/>
      </mesh>

      <mesh position={[0, length/2, 0]}>
        <coneBufferGeometry args={[.5, 1, 32]} />
        <meshBasicMaterial color={"black"}/>
      </mesh>
      <mesh position={[0, -length/2, 0]} rotation={[ Math.PI, 0, 0]}>
        <coneBufferGeometry args={[.5, 1, 32]} />
        <meshBasicMaterial color={"black"}/>
      </mesh>

      <Sprite scale={[6, 6, 6]} opacity={1} position={[0, 0, 0]}>
        {length*100}
      </Sprite>
    </group>
  )
}

export default LengthArrow