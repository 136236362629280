import Floor from './floor';
import Leftwall from './leftwall';
import Rightwall from './rightwall';
import Frontwall from './frontwall';
import Backwall from './backwall';
import Marker from './marker'

const Pool = () => {

  return (
    <>
      <Floor/>
      <Leftwall/>
      <Rightwall/>
      <Frontwall/>
      <Backwall/>
      <Marker/>
    </>
  )
} 

export default Pool