import { useStore } from "../../store/store"
import Shadermateriaal from "../shadermateriaal"

const StuctaLounge = () => {
  const {
    stair_amount,
    pooldepth,
    poolwidth,
    poollength,
    stairsleft,
    drawingplan
  } = useStore((state) => state.pool)

  return (
    <>
      {(stairsleft === "right" || stairsleft === 'both') &&
        <>
          <group name="stairs" position={drawingplan ? [-poolwidth / 2.02 + (5 / 2), -(pooldepth / 2) + 0.25, (poollength / 2) - 8.1] : [-poolwidth / 2 + (5 / 2), -(pooldepth / 2), (poollength / 2) - 8]}>
            {
              [...Array(stair_amount)].map((e , i) => (
                <mesh key={i} name={`stairstep ${i}`} position={drawingplan ?[0, -2.6 - (i * 1.3), -(i * 3.7) - 1.85] : [0, -2.4 - (i * 1.3), -(i * 3.7) - 1.65]}>
                  <boxBufferGeometry args={[5, pooldepth - 4.6 - (2.6 * i), drawingplan ? 3.7 : 3.8]}/>
                  {drawingplan ?
                      <Shadermateriaal/>
                    :
                      <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
                  }
                </mesh>
              ))
            }
          </group>
          <mesh name="structablock" position={drawingplan ? [-poolwidth / 2.02 + (5 / 2), -(pooldepth / 2) - 1 - 0.05, (poollength / 2) - 4.1] : [-poolwidth / 2 + (5 / 2), -(pooldepth / 2) - 1, (poollength / 2) - 4]}>
            <boxBufferGeometry args={[5, pooldepth - 2, 8]}/>
            {drawingplan ?
                <Shadermateriaal/>
              :
                <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
            }
          </mesh>
        </>
      }

      {(stairsleft === "left" || stairsleft === 'both') &&
        <>
          <group name="stairs" position={drawingplan ? [poolwidth / 2.45, -(pooldepth / 2) + 0.25, (poollength / 2) - 8.1] : [poolwidth / 2.4, -(pooldepth / 2) + 0.15, (poollength / 2) - 8]}>
            {
              [...Array(stair_amount)].map((e , i) => (
                <mesh key={i} name={`stairstep ${i}`} position={[0, -2.6 - (i * 1.3), drawingplan ? - (i * 3.7) - 1.85 : - (i * 3.6) - 1.75]}>
                  <boxBufferGeometry args={[5, pooldepth - 4.6 - (2.6 * i), 3.7]}/>
                  {drawingplan ?
                      <Shadermateriaal/>
                    :
                      <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
                  }
                </mesh>
              ))
            }
          </group>
          <mesh name="structablock" position={drawingplan ? [poolwidth / 2.45, -(pooldepth / 2) - 1 - 0.05, (poollength / 2) - 4.1] : [poolwidth / 2.4, -(pooldepth / 2) - 1, (poollength / 2) - 4]}>
            <boxBufferGeometry args={[5, pooldepth - 2, 8]}/>
            {drawingplan ?
                <Shadermateriaal/>
              :
                <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
            }
          </mesh>
        </>
      }
    </>
  )
}

export default StuctaLounge