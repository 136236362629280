// import { DirectionalLightHelper } from "three";
// import { useHelper } from '@react-three/drei'
// import { useRef } from 'react';

const Lights = () => {
  // const directLight = useRef(null)
  // const directLight2 = useRef(null)
  // useHelper(directLight, DirectionalLightHelper, 1, "red")
  // useHelper(directLight2, DirectionalLightHelper, 1, "green")

  return (
    <>
      <ambientLight color={0xAFAFAF} name="ambient" intensity={0.65}/>
      <directionalLight position={[8, 271, 55]} color={0xffffff} intensity={1}/>
      <directionalLight position={[-8, 271, -55]} color={0xffffff} intensity={1}/>
    </>
  )
}

export default Lights