import './App.css';
import {Canvas, useThree} from "react-three-fiber"
import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import Pool from './components/pool';
import Controls from './components/controls';
import Lights from './components/lights';
import Cover from './components/cover';
import Stairs from './components/stairs';
import { useEffect, useRef } from 'react';
import { useStore } from './store/store';
import Vijver from './components/vijver';
import Poollights from './components/poollights';
import Legende from './components/legende';
import MarkerButtons from './components/markerbuttons'
import Arrows from "./components/arrows"

const GetInfo = () => {
  const screenshot = useStore((state) => state.pool.screenshot)
  const { gl, scene, camera } = useThree()

  useEffect(() => {
    if (screenshot) {
      gl.render(scene, camera)
      const test = gl.domElement.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const link = document.createElement('a')
      link.setAttribute('download', 'canvas.png')
      link.setAttribute('href', test)
      link.click()
    }
  }, [screenshot])

  return null;
}


function App() {
  const meshRef = useRef()
  const canvasRef = useRef()
  const rotate180 = useStore((state) => state.pool.rotate180)
  const updateObject = useStore((state) => state.updateObject)
  const vijver = useStore((state) => state.pool.vijver)
  const lights_hidden = useStore((state) => state.pool.lights_hidden)
  const cameraPosition = useStore((state) => state.camera)
  const screenshot = useStore((state) => state.pool.screenshot)

  return (
    <div className='container'>
      <div className='poolbuild' >
        <Canvas ref={canvasRef}>
          <fog attach="fog" args={[0x7ad0ff, 10, 3500]} />
          <color attach="background" args={[0xf1f1f1]} />

          <Lights/>

          <PerspectiveCamera 
            makeDefault 
            aspect={window.innerWidth * 0.66 / window.innerHeight}
            near={1}
            far={1000}
            fov={40}
            position={cameraPosition.position}
          />

          <group name="pool" rotation={[0, rotate180 ? 0 : Math.PI, 0]} ref={meshRef}>
            <Pool/>
            <Cover/>
            <Stairs/>
            {vijver &&
              <Vijver/>
            }
            {!lights_hidden &&
              <Poollights/>
            }
            <Arrows/>
          </group>

          <OrbitControls/>
          <GetInfo/>
        </Canvas>
      </div>
      <MarkerButtons/>
      <Legende/>
      <Controls meshRef={meshRef}/>
      <button onClick={() => updateObject("pool", "screenshot", !screenshot)} style={{position: "absolute", top : 50, left : 0}}>make screenshot (dubbel click)</button>
    </div>
  );
}

export default App;
