import { useStore } from "../../store/store"
import Shadermateriaal from "../shadermateriaal"

const CompletoLounge = () => {
  const {
    stair_amount,
    pooldepth,
    poolwidth,
    poollength,
    drawingplan
  } = useStore((state) => state.pool)

  return (
    <>
      <group name="stairs" position={[0, -(pooldepth / 2) + 0.25, drawingplan ? (poollength / 2) -8.05 :(poollength / 2) -8]}>
        {
          [...Array(stair_amount)].map((e , i) => (
            <mesh key={i} name={`stairstep ${i}`} position={[0, -2.6 - (i * 1.3), drawingplan ? - (i * 3.65) - 1.85 : - (i * 3.6) - 1.8]}>
              <boxBufferGeometry args={[drawingplan ? poolwidth - .2 : poolwidth, pooldepth - 4.6 - (2.6 * i), 3.6]}/>
              {drawingplan ?
                  <Shadermateriaal/>
                :
                  <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
              }
            </mesh>
          ))
        }
      </group>
      <mesh name="structablock" position={drawingplan ? [0, -(pooldepth / 2) - 1 - 0.05, (poollength / 2) - 4.1] : [0, -(pooldepth / 2) - 1, (poollength / 2) - 4]}>
        <boxBufferGeometry args={[poolwidth -.2, pooldepth - 2, 8]}/>
        {drawingplan ?
            <Shadermateriaal/>
          :
            <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
        }
      </mesh>
    </>
  )
}

export default CompletoLounge