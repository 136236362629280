import { useStore } from "../../../store/store"
import Shadermateriaal from "../../shadermateriaal"
import {AddXMarker, addXMarkerLine} from "../../../hooks/AddMarkers"
import {MoveXMarker, LeaveMarker} from "../../../hooks/MoveMarker"

const BackwallLogic = () => {
  const {poolwidth, pooldepth, wallwidth, drawingplan} = useStore((state) => state.pool)
  const backwallMarkers = useStore((state) => state.backwall.markers)
  const updateObject = useStore((state) => state.updateObject)
  const marker = useStore((state) => state.marker)
  const {index, wall} = useStore((state) => state.deleteMarker)
  const isempty = marker.position1.length === 0

  return (
    <>
      <mesh 
        name="backwall_base"
        onClick={(e) => {
          if (marker.shape === "line") {
            addXMarkerLine('backwall', e, backwallMarkers, pooldepth, updateObject, marker, isempty)
          }else {
            AddXMarker('backwall', e, pooldepth, updateObject, backwallMarkers, marker)
          }
        }}

        onPointerMove={(e) => {
          MoveXMarker(e, pooldepth, updateObject, marker, 'back')
        }}
        onPointerLeave={(e) => {
          LeaveMarker(e, updateObject, 'back')
        }}
      >
        <boxBufferGeometry args={[poolwidth + 0.2, pooldepth + 0.1, wallwidth]} />
        {drawingplan ?
            <Shadermateriaal/>
          :
            <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
        }
      </mesh>
      {backwallMarkers.map((item, i) => (
        item.shape === "line" ? (
          <group 
            key={i}
            onClick={() => { 
              updateObject("deleteMarker", "index", i)
              updateObject("deleteMarker", "wall", "backwall")
              updateObject("deleteMarker", "markers", backwallMarkers)
            }}
          >
            <mesh position={[-item.x1, -item.y1 + pooldepth/2, 0]}>
              <boxBufferGeometry args={[item.size, item.size, item.size]}/>
              <meshStandardMaterial color={wall === "backwall" && index !== i ? "grey" : item.color}/>
            </mesh>

            <mesh position={[(-item.x1 + -item.x2)/2, -item.y1 + pooldepth/2, 0]}>
              <boxBufferGeometry args={[Math.sqrt( Math.pow((item.x1-item.x2), 2) + Math.pow((item.y1-item.y2), 0)) - item.size, item.size/2, item.size/2]}/>
              <meshStandardMaterial color={wall === "backwall" && index !== i ? "grey" : item.color}/>
            </mesh>


            <mesh position={[-item.x2, -item.y2 + pooldepth/2, 0]}>
              <boxBufferGeometry args={[item.size, item.size, item.size]}/>
              <meshStandardMaterial color={wall === "backwall" && index !== i ? "grey" : item.color}/>
            </mesh>
          </group>
        ) : (
          <mesh 
            key={i} 
            position={[-item.x, item.y + pooldepth/2, 0]}
            onClick={() => { 
              updateObject("deleteMarker", "index", i)
              updateObject("deleteMarker", "wall", "backwall")
              updateObject("deleteMarker", "markers", backwallMarkers)
            }}
          >
            <boxBufferGeometry args={[item.size, item.size, item.size]}/>
            <meshStandardMaterial color={wall === "backwall" && index !== i ? "grey" : item.color}/>
          </mesh>
        )
      ))}
    </>
  )
}

export default BackwallLogic