import Sprite from "../sprite"
import { useStore } from "../../store/store"

const DepthArrow = () => {
  const length = useStore((state) => state.pool.poollength)
  const width = useStore((state) => state.pool.poolwidth)
  const depth = useStore((state) => state.pool.pooldepth)
  const position = useStore((state) => state.arrows.deptharrow_position)

  return (
    <group name="depth arrow" rotation={[0, Math.PI, Math.PI]} position={position.length === 0 ? [-width/2, -depth/2, length/2+2.5] : position}>
      <mesh position={[0, 0, 0]} >
        <cylinderBufferGeometry attach="geometry" args={[.2, .2, depth, 32]} />
        <meshStandardMaterial color={"grey"}/>
      </mesh>

      <mesh position={[0, depth/2, 0]}>
        <coneBufferGeometry args={[.5, 1, 32]} />
        <meshStandardMaterial color={"grey"}/>
      </mesh>
      <mesh position={[0, -depth/2, 0]} rotation={[ Math.PI, 0, 0]}>
        <coneBufferGeometry args={[.5, 1, 32]} />
        <meshStandardMaterial color={"grey"}/>
      </mesh>

      <Sprite scale={[6, 6, 6]} opacity={1} position={[0, 0, -4.5]}>
        {depth*100}
      </Sprite>
    </group>
  )
}

export default DepthArrow