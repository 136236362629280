import { ColladaExporter } from 'three/examples/jsm/exporters/ColladaExporter';

const Dae = ({meshRef}) => {
  const exporter = new ColladaExporter()


  const date = new Date();
  const month = date.getMonth()+1;
  const year = date.getFullYear();
  const day = date.getDate();
  const hour = date.getHours();
  const min = date.getMinutes();


  const handleDownload = () => {
    const result = exporter.parse( meshRef.current );
    saveString( result.data, `CarroPool_3D_${year}_${month}_${day}_${hour}_${min}.dae`);
  }

  const saveString = ( text, filename ) => {
    save( new Blob( [ text ], { type: 'text/plain' } ), filename );
  }

  const save = ( blob, filename ) => {
    const link = document.createElement( 'a' );
    link.style.display = 'none';
    document.body.appendChild( link );
    const href = URL.createObjectURL( blob );
    link.href = href
    link.download = filename;
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } 

  return (
    <button onClick={() => handleDownload()}>Dae file</button>
  )
}

export default Dae