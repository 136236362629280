import { useStore } from "../../store/store"
import Shadermateriaal from '../shadermateriaal';
import {AddZMarker, addZMarkerLine} from "../../hooks/AddMarkers"
import {MoveZMarker, LeaveMarker} from "../../hooks/MoveMarker"

const Vijver = () => {
  const poolwidth = useStore((state) => state.pool.poolwidth)
  const poollength = useStore((state) => state.pool.poollength)
  const pooldepth = useStore((state) => state.pool.pooldepth)
  const wallwidth = useStore((state) => state.pool.wallwidth)
  const vijverdikte = useStore((state) => state.pool.vijverdikte)
  const drawingplan = useStore((state) => state.pool.drawingplan)
  const vijverwallMarkers = useStore((state) => state.vijverwall.markers) 
  const updateObject = useStore((state) => state.updateObject)
  const marker = useStore((state) => state.marker)
  const {index, wall} = useStore((state) => state.deleteMarker)
  const isempty = marker.position1.length === 0
  
  return (
    <>
      <mesh 
        name="vijver"
        onClick={(e) => {
          if (marker.shape === "line") {
            addZMarkerLine('vijverwall', e, vijverwallMarkers, pooldepth, updateObject, marker, isempty)
          }else {
            AddZMarker('vijverwall', e, pooldepth, updateObject, vijverwallMarkers, marker)
          }
        }}

        onPointerMove={(e) => {
          MoveZMarker(e, pooldepth, updateObject, marker, 'front')
        }}
        onPointerLeave={(e) => {
          LeaveMarker(e, updateObject, 'front')
        }} 
        position={[0, -(pooldepth / 2) - 0.05, -poollength + 55]}
      >
        <boxBufferGeometry args={[drawingplan ? poolwidth -0.2 : poolwidth, pooldepth + 0.1, vijverdikte ? wallwidth +.2 : wallwidth]} />
        
        {drawingplan ?
            <Shadermateriaal/>
          :
            <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
        }
      </mesh>
      {vijverwallMarkers.map((item, i) => (
        item.shape === "line" ? (
          <group 
            key={i}
            onClick={() => { 
              updateObject("deleteMarker", "index", i)
              updateObject("deleteMarker", "wall", "vijverwall")
              updateObject("deleteMarker", "markers", vijverwallMarkers)
            }}
          >
            <mesh position={[-item.x1, -item.y1 + pooldepth/2, 0]}>
              <boxBufferGeometry args={[item.size, item.size, item.size]}/>
              <meshStandardMaterial color={wall === "vijverwall" && index !== i ? "grey" : item.color}/>
            </mesh>

            <mesh position={[(-item.x1 + -item.x2)/2, -item.y1 + pooldepth/2, 0]}>
              <boxBufferGeometry args={[Math.sqrt( Math.pow((item.x1-item.x2), 2) + Math.pow((item.y1-item.y2), 0)) - item.size, item.size/2, item.size/2]}/>
              <meshStandardMaterial color={wall === "vijverwall" && index !== i ? "grey" : item.color}/>
            </mesh>


            <mesh position={[-item.x2, -item.y2 + pooldepth/2, 0]}>
              <boxBufferGeometry args={[item.size, item.size, item.size]}/>
              <meshStandardMaterial color={wall === "vijverwall" && index !== i ? "grey" : item.color}/>
            </mesh>
          </group>
        ) : (
          <mesh 
            key={i} 
            position={[-item.x, item.y, -item.z]}
            onClick={() => { 
              updateObject("deleteMarker", "index", i)
              updateObject("deleteMarker", "wall", "vijverwall")
              updateObject("deleteMarker", "markers", vijverwallMarkers)
            }}
          >
            <boxBufferGeometry args={[item.size, item.size, item.size]}/>
            <meshStandardMaterial color={wall === "vijverwall" && index !== i ? "grey" : item.color}/>
          </mesh>
        )
      ))}
    </>
  )
} 

export default Vijver