import { useStore } from '../../store/store';
import { Shape } from 'three';
import Shadermateriaal from '../shadermateriaal';

const Cover = () => {
  const {
    poolwidth,
    poollength,
    pooldepth,
    cover_chosen,
    pool_plage,
    drawingplan
  } = useStore((state) => state.pool)

  var nis_side_shape = new Shape();
  nis_side_shape.moveTo(0, 0);
  nis_side_shape.lineTo(-7, 0);
  nis_side_shape.lineTo(-7, 0.9);
  nis_side_shape.lineTo(0, 0);
 
  return (
    <group name="cover block">
      {cover_chosen === "nis" &&
        <>
          <group position={[0, 0, -(poollength / 2) - 3.45]}>
            <mesh name="nis_fundament" position={[0, -(pooldepth - 2) / 2 - 1.5 - 2.59, 0]}>
              <boxBufferGeometry args={[poolwidth, pooldepth - 5.9, 7]}/>
              {drawingplan ?
                  <Shadermateriaal/>
                :
                  <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
              }
            </mesh>
            <mesh name="nis_cover" position={[0, -5.52, -0.05]} rotation={[-9 * Math.PI / 215, 0, 0]}>
              <boxBufferGeometry args={[poolwidth, 0.2, 7 / Math.cos(12 * Math.PI / 180)]}/>
              {drawingplan ?
                  <Shadermateriaal/>
                :
                  <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
              }
            </mesh>
            {/* <mesh name="nis_cover_front" position={[0, -2.6, 4.0]}>
              <boxBufferGeometry args={[poolwidth, 5, 0.2]}/>
              {drawingplan ?
                  <Shadermateriaal/>
                :
                  <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
              }
            </mesh> */}
            <mesh name="nis_insides_side_left" rotation={[0, Math.PI / 2, 0]} position={[poolwidth / 2, -6.04, -3.5]}>
              <shapeBufferGeometry args={[nis_side_shape]}/>
              {drawingplan ?
                  <Shadermateriaal/>
                :
                  <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
              }
            </mesh>
            <mesh name="nis_insides_side_right" rotation={[3.01, 11, 0]} position={[-poolwidth / 2, -6.06, -3.5]}>
              <shapeBufferGeometry args={[nis_side_shape]}/>
              {drawingplan ?
                  <Shadermateriaal/>
                :
                  <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
              }
            </mesh>
            <mesh name="nis_insides_back" position={[0, -5.5, 3.4]}>
              <boxBufferGeometry args={[poolwidth, 0.9, 0.2]}/>
              {drawingplan ?
                  <Shadermateriaal/>
                :
                  <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
              }
            </mesh>
          </group>
          {pool_plage &&
            <mesh position={drawingplan ? [0, -(pooldepth / 2) - 1 - 0.05, (poollength / 2.21)] : [0, -(pooldepth / 2) - 1, (poollength / 2.2)]}>
              <boxBufferGeometry args={[poolwidth -.2, pooldepth - 2, 7.8]}/>
              {drawingplan ?
                  <Shadermateriaal/>
                :
                  <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
              }
            </mesh>
          }
        </>
      }
      {cover_chosen === "plage" &&
        <mesh position={drawingplan ? [0, -(pooldepth / 2) - 1 - 0.05, (poollength / 2.21)] : [0, -(pooldepth / 2) - 1, (poollength / 2.2)]}>
          <boxBufferGeometry args={[poolwidth - .2, pooldepth - 2, 7.8]}/>
          {drawingplan ?
              <Shadermateriaal/>
            :
              <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
          }
        </mesh>
      }
    </group>
  )
}

export default Cover