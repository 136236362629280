import { useStore } from "../../../store/store"
import Shadermateriaal from "../../shadermateriaal"
import {AddZMarker, addZMarkerLine} from "../../../hooks/AddMarkers"
import {MoveZMarker, LeaveMarker} from "../../../hooks/MoveMarker"

const RightwallLogic = () => {
  const {poollength, pooldepth, wallwidth, drawingplan} = useStore((state) => state.pool)
  const rightwallMarkers = useStore((state) => state.rightwall.markers)
  const updateObject = useStore((state) => state.updateObject)
  const marker = useStore((state) => state.marker)
  const {index, wall} = useStore((state) => state.deleteMarker)
  const isempty = marker.position1.length === 0

  return (
    <>
      <mesh 
        name="rightwall_base"
        onClick={(e) => {
          if (marker.shape === "line") {
            addZMarkerLine('rightwall', e, rightwallMarkers, pooldepth, updateObject, marker, isempty)
          }else {
            AddZMarker('rightwall', e, pooldepth, updateObject, rightwallMarkers, marker)
          }
        }}

        onPointerMove={(e) => {
          MoveZMarker(e, pooldepth, updateObject, marker, 'right')
        }}
        onPointerLeave={(e) => {
          LeaveMarker(e,updateObject, 'right')
        }}
      >
        <boxBufferGeometry args={[wallwidth, pooldepth + 0.1, drawingplan ?  poollength - 0.2 : poollength + 0.2]} />
          {drawingplan ?
              <Shadermateriaal/>
            :
              <meshStandardMaterial color={0xffffff} roughness={1} metalness={0.2}/>
          }
      </mesh>

      {rightwallMarkers.map((item, i) => (
        item.shape === "line" ? (
          <group 
            key={i} 
            onClick={() => { 
              updateObject("deleteMarker", "index", i)
              updateObject("deleteMarker", "wall", "rightwall")
              updateObject("deleteMarker", "markers", rightwallMarkers)
            }}
          >
            <mesh position={[0, -item.y1 + pooldepth/2, -item.z1]}>
              <boxBufferGeometry args={[item.size, item.size, item.size]}/>
              <meshStandardMaterial color={wall === "rightwall" && index !== i ? "grey" : item.color}/>
            </mesh>

            <mesh position={[0, -item.y1 + pooldepth/2, (-item.z1 + -item.z2)/2]}>
              <boxBufferGeometry args={[item.size/2, item.size/2, Math.sqrt( Math.pow((item.z1-item.z2), 2) + Math.pow((item.y1-item.y2), 0)) - item.size]}/>
              <meshStandardMaterial color={wall === "rightwall" && index !== i ? "grey" : item.color}/>
            </mesh>


            <mesh position={[0, -item.y2 + pooldepth/2, -item.z2]}>
              <boxBufferGeometry args={[item.size, item.size, item.size]}/>
              <meshStandardMaterial color={wall === "rightwall" && index !== i ? "grey" : item.color}/>
            </mesh>
          </group>
        ): (
          <mesh 
            key={i} 
            position={[0, item.y + pooldepth/2, -item.z]}
            onClick={() => { 
              updateObject("deleteMarker", "index", i)
              updateObject("deleteMarker", "wall", "rightwall")
              updateObject("deleteMarker", "markers", rightwallMarkers)
            }}
          >
            <boxBufferGeometry args={[item.size, item.size, item.size]}/>
            <meshStandardMaterial color={wall === "rightwall" && index !== i ? "grey" : item.color}/>
          </mesh>
        )
      ))}
    </>
  )
}

export default RightwallLogic