import { useEffect, useState } from "react"
import { useStore } from "../../store/store"
import { Html } from "@react-three/drei";

const TrackingArrowY = ({position}) => {
  const depth = useStore((state) => state.pool.pooldepth)
  const updateObject = useStore((state) => state.updateObject)
  const {buislengthY, nulPuntY} = useStore((state) => state.tracker)
  const x = position[0]
  const y = position[1]
  const z = position[2]

  useEffect(() => {
    updateObject("tracker", "buislengthY", depth  - Math.abs(y))
    updateObject("tracker", "nulPuntY", buislengthY / 2)
  }, [y])

  return (
    <group name="depth arrow" 
      position={[x, buislengthY < 7.5 ? y - nulPuntY : y/2, z]}
    >
      <group rotation={buislengthY < 7.5 ? [0, Math.PI, Math.PI] : [0, Math.PI/2, Math.PI/.5]} >
        <mesh position={[0, 0, 0]} >
          <cylinderBufferGeometry attach="geometry" args={[.2, .2, buislengthY < 7.5 ? buislengthY : depth - buislengthY, 32]} />
          <meshStandardMaterial color={"grey"}/>
        </mesh>
      </group>

      <Html position={[0, 0, -4.5]}>
        <p>{+(Math.round(buislengthY + "e+2")  + "e-2")}</p>
      </Html>
    </group>
  )
}

export default TrackingArrowY
